export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavTitle',
        _children: ['Projects']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'TYPO3 Project List',
        to: '/projects/typo3',
        icon: 'cil-drop'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Application Project List',
        to: '/projects/application',
        icon: 'cil-pencil'
      }
    ]
  }
]